import React from 'react';
import PropTypes from 'prop-types';
import { IoIosClose } from 'react-icons/io';
import { ContainerCrt } from './criteria.css';

export class Criteria extends React.Component {
  render() {
    let {
      titleInc,
      titleExc,
      textExc,
      textInc,
      noInclusion,
      noExclusion,
      hideModal,
    } = this.props;
    return (
      <ContainerCrt>
        <IoIosClose onClick={hideModal} />
        <h3>{titleInc}</h3>
        {textInc && textInc !== '-' ? (
          <pre>{textInc}</pre>
        ) : (
          <pre>{noInclusion}</pre>
        )}
        <h3>{titleExc}</h3>
        {textExc && textExc !== '-' ? (
          <pre>{textExc}</pre>
        ) : (
          <pre>{noExclusion}</pre>
        )}
      </ContainerCrt>
    );
  }
}

Criteria.propTypes = {
  titleInc: PropTypes.string,
  titleExc: PropTypes.string,
  textInc: PropTypes.string,
  textExc: PropTypes.string,
  noInclusion: PropTypes.string,
  noExclusion: PropTypes.string,
  hideModal: PropTypes.any,
};
