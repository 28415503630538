import styled from 'styled-components';

export const ContainerCrt = styled.div`
  padding: 16px 30px;
  svg {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 30px;
    background-color: #10384f;
    color: #ffffff;
    cursor: pointer;
    :focus {
      outline: none;
    }
  }
  h3 {
    font-size: 30px;
    color: #10384f;
    font-family: 'HelveticaLTStd-light', 'Helvetica Neue', Helvetica, sans-serif;
    font-weight: 500;
    margin: 0 0 30px;
    position: relative;
    padding: 0 0 8px;
    text-align: center;
    line-height: 32px;
    ::after {
      width: 108px;
      height: 2px;
      background-color: #10384f;
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: -54px;
      content: ' ';
    }
  }
  pre {
    white-space: pre-line;
    border: 0;
    color: #10384f;
    font-size: 14px;
    line-height: 1.5;
    margin: 0 0 10px 0;
    padding: 0;
  }
`;
