import styled from 'styled-components';
export const PrescreenerButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 767px) {
    justify-content: center;
    margin-top: 8px;
  }
  a {
    max-width: fit-content;
    display: block;
    font-family: 'HelveticaLTStd-BoldObl', 'Helvetica Neue', Helvetica,
      sans-serif;
    font-size: 14px;
    line-height: 18px;
    word-break: break-word;
    text-transform: uppercase;
    color: #ffffff;
    position: relative;
    height: fit-content;
    padding: 8px 46px 8px 15px;
    text-decoration: none;
    margin-bottom: 8px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background-color: #66b512;
    border: solid 1px #66b512;
    span {
      display: flex;
      position: absolute;
      right: -1px;
      top: -1px;
      width: 31px;
      height: calc(100% + 2px);
      justify-content: center;
      align-items: center;
      background-color: #4a8c0f;
      svg {
        width: 24px;
        height: 24px;
      }
      ::before {
        background: linear-gradient(
          to right bottom,
          transparent 49%,
          #4a8c0f 50%
        );
        content: '';
        position: absolute;
        left: -14px;
        bottom: 0;
        height: 100%;
        width: 14px;
      }
    }
    :hover {
      background-color: #76cd19;
      border: solid 1px #76cd19;
    }
    :focus {
      box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
    }
  }
`;
