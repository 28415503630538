import React from 'react';

export const nl2br = text =>
  text
    .split(/(?:\r\n|\r|\n)/)
    .reduce(
      (res, frag, i, arr) => [
        ...res,
        tabHtml(frag),
        ...(i < arr.length - 1 ? [React.createElement('br')] : []),
      ],
      []
    );

export const tabHtml = text => text.replace('\t', '\u00A0\u00A0\u00A0\u00A0');
