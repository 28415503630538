import React from 'react';
import { graphql, navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { Row, Col, Collapse, Card, CardBody } from 'reactstrap';
import Layout from '../components/layout/layout';
import ClampLines from 'react-clamp-lines';
import { Table, Tbody, Thead, Th, Tr, Td } from 'react-super-responsive-table';
import Moment from 'react-moment';
import {
  connectApiToken,
  connectApiUrl,
  trialDetailsUrl,
} from '../constants/config';
import axios from 'axios';
import NProgress from 'nprogress';
import Modal from '../components/modal/modal';
import ContactForm from 'components/form/contact-form';
import Redirect from '../components/redirect/redirect';
import { Criteria } from '../components/criteria/criteria';
import DownloadPopup from '../components/download-popup';
import ShareSection from '../components/share-buttons';
import { FaAngleLeft, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import {
  Fonts,
  Container,
  DetailsPage,
  DetailsHero,
  StyledTooltip,
  TrialPurposeSection,
  TrialSummarySection,
  MapContainer,
  DetailsTable,
  TrialDesignSection,
  ContactModal,
  StudyNotFoundContainer,
  ShareSectionWrapper,
} from '../global.css';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { trackAnalytics } from '../helpers/trackAnalytics';
import Map from '../components/map/map';
import { sortLocations, checkUserCountry } from '../components/map/map-utils';
import { nl2br } from '../helpers/text';
import { ConnectDataContext } from '../helpers/useConnectInfo';
import PreloaderConnectInfo from '../components/connect/PreloaderConnectInfo';
import PrescreenerButton from '../components/connect/PrescreenerButton';

// import BaiduMap from '../components/map/baidu-map';

class TrialDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      searchResult: props.data.studyDetails,
      searchParams:
        typeof window !== 'undefined'
          ? new URL(location.href).searchParams
          : null,
      isLoading: false,
      activeLocations: [],
      activeMarker: {},
      showingInfoWindow: false,
      modalOpen: false,
      trialDesignCollapsed: false,
      searchLat: null,
      searchLong: null,
      searchName: null,
      apiBadResponse: false,
      info: [],
    };
    this.isMobile = false;
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.collapseTrialDesign = this.collapseTrialDesign.bind(this);
    this.markers = null;
    this.statusToHideLocationStatus = [
      'RECSTATUS_COMPLETED',
      'RECSTATUS_STUDY_COMPLETED',
      'RECSTATUS_TERMINATED',
    ];
    this.hasPrequalify = false;
  }

  componentDidMount() {
    NProgress.start();
    this.setState({ isLoading: true });
    this.loadData();
    if (typeof window !== 'undefined') {
      this.isMobile = window.innerWidth && window.innerWidth < 768;
    }
    setTimeout(() => {
      if (
        typeof window !== 'undefined' &&
        window.location &&
        window.location.hash &&
        document.querySelector(window.location.hash)
      ) {
        window.scrollTo(
          0,
          document.querySelector(`${window.location.hash}`).offsetTop
        );
      }
    }, 2000);
  }

  componentWillUnmount() {
    if (this.markers && this.markers.length && this.cluster) {
      this.markers.forEach(marker => {
        this.cluster.removeMarker(marker);
      });
    }
  }

  setInfo(value) {
    this.setState({ info: value });
  }

  showModal() {
    this.setState({
      modalOpen: true,
    });
  }

  hideModal() {
    this.setState({
      modalOpen: false,
    });
  }

  collapseTrialDesign() {
    this.setState({ trialDesignCollapsed: !this.state.trialDesignCollapsed });
  }

  getEudraId(studyDetails) {
    const eudraCT =
      studyDetails.secondary_ids &&
      studyDetails.secondary_ids.find(ids => ids.id_type === 'EudraCT Number');
    return eudraCT ? eudraCT.id : null;
  }

  setAddThis(studyDetails) {
    const { data } = this.props;
    if (
      typeof window !== 'undefined' &&
      window.addthis &&
      window.addthis.update &&
      window.location &&
      window.location.href &&
      studyDetails
    ) {
      setTimeout(() => {
        window.addthis.update(
          'config',
          'ui_email_note',
          `'${data.trialDetailsEnJson.addThisWidgetParams.dataDescription} ${
            studyDetails.conditions && studyDetails.conditions.length
              ? studyDetails.conditions
                  .map(cond =>
                    cond['DisplayValue_OneLinkTx']
                      ? cond['DisplayValue_OneLinkTx']
                      : cond['DisplayValue:OneLinkTx']
                      ? cond['DisplayValue:OneLinkTx']
                      : cond.DisplayValue
                  )
                  .join(', ')
              : ''
          }'. ${data.trialDetailsEnJson.addThisWidgetParams.learnMore}`
        );
        window.addthis.update('share', 'url', `${window.location.href}`);
        window.addthis.update(
          'share',
          'title',
          `${data.trialDetailsEnJson.addThisWidgetParams.dataTitle} ${
            studyDetails.conditions && studyDetails.conditions.length
              ? studyDetails.conditions
                  .map(cond =>
                    cond['DisplayValue_OneLinkTx']
                      ? cond['DisplayValue_OneLinkTx']
                      : cond['DisplayValue:OneLinkTx']
                      ? cond['DisplayValue:OneLinkTx']
                      : cond.DisplayValue
                  )
                  .join(', ')
              : ''
          }`
        );
        window.addthis.update(
          'share',
          'description',
          `'${data.trialDetailsEnJson.addThisWidgetParams.dataDescription} ${
            studyDetails.conditions && studyDetails.conditions.length
              ? studyDetails.conditions
                  .map(cond =>
                    cond['DisplayValue_OneLinkTx']
                      ? cond['DisplayValue_OneLinkTx']
                      : cond['DisplayValue:OneLinkTx']
                      ? cond['DisplayValue:OneLinkTx']
                      : cond.DisplayValue
                  )
                  .join(', ')
              : ''
          }'. ${data.trialDetailsEnJson.addThisWidgetParams.learnMore}`
        );
        window.addthis.update('share', 'imp_url', 1);
      }, 300);
    }
  }

  loadData() {
    this.setAddThis(this.state.searchResult);
    let { location, navigate, data } = this.props,
      searchParams = null,
      elementId = null,
      oldId = null,
      searchLat = null,
      searchLong = null,
      searchRadius = null,
      baseLat = null,
      baseLong = null,
      locationName = null;
    try {
      searchParams = new URL(location.href).searchParams;
      elementId = this.props.pageContext.studyId;
      oldId = searchParams.get('oldId');
      searchLat = searchParams.get('searchLat');
      searchLong = searchParams.get('searchLong');
      searchRadius = searchParams.get('MileRadius');
      baseLat = searchParams.get('baseLat');
      baseLong = searchParams.get('baseLong');
      locationName = searchParams.get('name');
    } catch (e) {
      elementId = location.search.substr(4);
    }
    if (!elementId || elementId.length === 0) {
      NProgress.done();
      return navigate(
        '/studies/?conditions=&pcode=&locationCountryInternal=&Keyword=&gender=&ageRange=&Status=&healthyVol=&Latitude=&Longitude=&page=0&SortField=Location_Distance&SortOrder=asc&phases=&studyType=&studyResult='
      );
    }
    this.setState({
      isLoading: true,
      searchLat: searchLat,
      searchLong: searchLong,
      searchName: locationName,
      searchParams,
    });
    if (data.isRedirected) {
      NProgress.done();
      this.setState({
        isLoading: false,
      });
    } else {
      axios
        .get(trialDetailsUrl(elementId, oldId ? 'old' : ''))
        .then(res => {
          NProgress.done();
          const searchResults = res.data;

          trackAnalytics('TrialDetailsResponse', {
            TrialId: elementId,
            searchLat,
            searchLong,
            searchRadius,
            baseLat,
            baseLong,
            Success: true,
          });
          this.setAddThis(searchResults.protocol_form);
          this.setState({
            isLoading: false,
            searchResult: searchResults.protocol_form,
            id: searchResults.study_id,
          });
        })
        .catch(error => {
          trackAnalytics('TrialDetailsResponseFailure', {
            Error: error.message,
            TrialId: elementId,
            searchLat,
            searchLong,
            searchRadius,
            baseLat,
            baseLong,
          });
          NProgress.done();
          this.setState({
            apiBadResponse: true,
          });
        });
    }
  }

  setActiveLoc = locations => {
    this.setState({ activeLocations: locations });
  };

  getLocations = () => {
    const { data } = this.props;
    const { searchResult } = this.state;

    return searchResult.locations
      .filter(
        location =>
          location.StatusDisplayInternal !== 'LOCSTATUS_TERMINATED_WITHDRAWN'
      )
      .map(row => ({
        ...row,
        StudyId: searchResult.org_study_id,
        conditions:
          searchResult.conditions && searchResult.conditions.length
            ? searchResult.conditions
                .map(cond =>
                  cond['DisplayValue_OneLinkTx']
                    ? cond['DisplayValue_OneLinkTx']
                    : cond['DisplayValue:OneLinkTx']
                    ? cond['DisplayValue:OneLinkTx']
                    : cond.DisplayValue
                )
                .join(', ')
            : data.trialDetailsEnJson.notAvailable,
      }));
  };

  getSortedLocation = locations => {
    const { searchLat, searchLong, searchName } = this.state;
    return sortLocations(
      locations,
      searchLat,
      searchLong,
      'distance',
      searchName
    );
  };

  renderTableRow(row, index) {
    const { data } = this.props;
    const { searchResult } = this.state;
    return (
      <Tr key={`details-row-${index}`} className={'table-row'}>
        <Td className={'location'}>
          <p>
            {row.Name ? row.Name : data.trialDetailsEnJson.investigativeSite}
          </p>
          <p>{`${row.City ? row.City : data.trialDetailsEnJson.manyLoc}${
            row.CountryDisplay
              ? `, ${row.CountryDisplay}`
              : data.trialDetailsEnJson.notAvailable
          }${row.PostalCode ? `, ${row.PostalCode}` : ''}`}</p>
        </Td>
        {!this.statusToHideLocationStatus.includes(
          searchResult.display_status_internal
        ) ? (
          <Td className={'status'}>
            <div
              className={
                'status-icon ' +
                `${row.StatusDisplayInternal}`
                  .toLocaleLowerCase()
                  .replace(/\s/g, '_')
                  .replace(/\W/g, '')
              }
            />
            {row.StatusDisplay}
          </Td>
        ) : null}

        <Td className={'print-contact'}>
          <p>
            <strong>{`${data.trialDetailsEnJson.contactUs}:`}</strong>
          </p>
          <p>
            <strong>{`${data.trialDetailsEnJson.email}: `}</strong>
            {`${
              searchResult.overall_contact.email
                ? searchResult.overall_contact.email
                : data.trialDetailsEnJson.notAvailable
            }`}
          </p>
          <p>
            <strong>{`${data.trialDetailsEnJson.phone}: `}</strong>
            {`${
              searchResult.overall_contact.phone
                ? searchResult.overall_contact.phone
                : data.trialDetailsEnJson.notAvailable
            }`}
          </p>
        </Td>
      </Tr>
    );
  }
  renderResultsTable() {
    let { data } = this.props;
    let locations = null;
    if (
      this.state.activeLocations !== null &&
      typeof this.state.activeLocations !== 'undefined' &&
      this.state.activeLocations.length > 0
    ) {
      locations = sortLocations(
        this.state.activeLocations,
        this.state.searchLat,
        this.state.searchLong,
        'distance',
        this.state.searchName
      );
    } else {
      locations = this.state.searchResult.locations;
    }
    return (
      <Table>
        <Thead>
          <Tr>
            <Th>{data.trialDetailsEnJson.locations}</Th>
            {!this.statusToHideLocationStatus.includes(
              this.state.searchResult.display_status_internal
            ) ? (
              <Th>{data.trialDetailsEnJson.status}</Th>
            ) : null}
          </Tr>
        </Thead>
        <Tbody>
          {locations
            .filter(
              location =>
                location.StatusDisplayInternal !==
                'LOCSTATUS_TERMINATED_WITHDRAWN'
            )
            .map(this.renderTableRow.bind(this))}
        </Tbody>
      </Table>
    );
  }

  renderContent = () => {
    const { data } = this.props;
    const { searchResult, searchParams, isLoading } = this.state;
    const eudraId = this.getEudraId(searchResult);

    if (!searchResult || isLoading) return null;
    return (
      <ConnectDataContext.Provider
        value={{
          info: this.state.info,
          setInfo: this.setInfo.bind(this),
          connectApiUrl: connectApiUrl(),
          connectApiToken: connectApiToken(),
        }}
      >
        <ShareSectionWrapper>
          <Row>
            <Col xs={12} md={{ size: 4, offset: 8 }}>
              <div className="share-section">
                <p>{data.trialDetailsEnJson.socialMedia}</p>
                <ShareSection
                  data={{
                    dataTitle: `${
                      data.trialDetailsEnJson.addThisWidgetParams.dataTitle
                    } ${
                      searchResult.conditions
                        ? searchResult.conditions
                            .filter(val => val.LookupValue)
                            .map(val => val.DisplayValue)
                            .join(', ')
                        : ''
                    }`,
                    dataDescription: `${
                      data.trialDetailsEnJson.addThisWidgetParams
                        .dataDescription
                    } ${
                      searchResult.conditions
                        ? searchResult.conditions
                            .filter(val => val.LookupValue)
                            .map(val => val.DisplayValue)
                            .join(', ')
                        : ''
                    }`,
                    learnMore:
                      data.trialDetailsEnJson.addThisWidgetParams.learnMore,
                  }}
                />
                <StyledTooltip
                  trigger="hover"
                  placement="top"
                  target="print"
                  className="align-print-tooltip"
                >
                  {data.trialDetailsEnJson.popoversText.print}
                </StyledTooltip>
              </div>
            </Col>
          </Row>
        </ShareSectionWrapper>
        <DetailsHero>
          <button
            type="button"
            className="go-back-button"
            onClick={event => {
              event.preventDefault();

              [
                'id',
                'name',
                'baseLat',
                'baseLong',
                'searchLat',
                'searchLong',
              ].forEach(el => searchParams.delete(el));

              navigate(
                `/studies/${
                  searchParams.toString().length ? '?' + searchParams : ''
                }`
              );
            }}
          >
            <FaAngleLeft />
            {data.trialDetailsEnJson.returnSearch}
          </button>
          <Row className={'data-display-row'}>
            <Col xs={12}>
              <div className="details-title">
                <p className="title-conditions">
                  {data.trialDetailsEnJson.heroTitle}:
                </p>
                <h1>
                  {searchResult.conditions && searchResult.conditions.length > 0
                    ? searchResult.conditions
                        .map(cond =>
                          cond['LookupValue_OneLinkTx']
                            ? cond['LookupValue_OneLinkTx']
                            : cond['LookupValue:OneLinkTx']
                            ? cond['LookupValue:OneLinkTx']
                            : cond.LookupValue
                            ? cond.LookupValue
                            : cond.DisplayValue
                        )
                        .join(', ')
                    : data.trialDetailsEnJson.shortNotAvailable}
                </h1>
                <p>
                  {searchResult['brief_title_OneLinkTx']
                    ? searchResult['brief_title_OneLinkTx']
                    : searchResult['brief_title:OneLinkTx']
                    ? searchResult['brief_title:OneLinkTx']
                    : searchResult.brief_title
                    ? searchResult.brief_title
                    : ''}
                  {searchResult.acronym && (
                    <span>{` (${searchResult.acronym})`}</span>
                  )}
                </p>
              </div>
            </Col>
            <Col className={'id-section'} xs={12}>
              <div>
                <h3>
                  {data.trialDetailsEnJson.identifier}:
                  <span className="fa fa-info-circle" id="study_id" />
                </h3>
                <StyledTooltip
                  trigger="hover"
                  placement="top"
                  target="study_id"
                >
                  {data.trialDetailsEnJson.popoversText.bayerId}
                </StyledTooltip>
                <p>
                  {searchResult.org_study_id
                    ? searchResult.org_study_id
                    : data.trialDetailsEnJson.notAvailable}
                </p>
              </div>
              <div>
                <h3>
                  {data.trialDetailsEnJson.clinicalID}:
                  <span className="fa fa-info-circle" id="clinicalID" />
                </h3>
                <StyledTooltip
                  trigger="hover"
                  placement="top"
                  target="clinicalID"
                >
                  {data.trialDetailsEnJson.popoversText.clinicalTrialsID}
                </StyledTooltip>
                <p>
                  {searchResult.nct_number ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://clinicaltrials.gov/study/${searchResult.nct_number}?rank=1`}
                    >
                      {searchResult.nct_number}
                    </a>
                  ) : (
                    data.trialDetailsEnJson.notAvailable
                  )}
                </p>
              </div>
              <div>
                <h3>
                  {data.trialDetailsEnJson.EudraCtID}:
                  <span className="fa fa-info-circle" id="eudraCtID" />
                </h3>
                <StyledTooltip
                  trigger="hover"
                  placement="top"
                  target="eudraCtID"
                >
                  {data.trialDetailsEnJson.popoversText.eudraCtId}
                </StyledTooltip>
                <p>
                  {eudraId ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://www.clinicaltrialsregister.eu/ctr-search/search?query=${eudraId}`}
                      title={eudraId}
                    >
                      {eudraId}
                    </a>
                  ) : (
                    data.trialDetailsEnJson.notAvailable
                  )}
                </p>
              </div>
              <div>
                <h3>
                  {data.trialDetailsEnJson.EUCtID}:
                  <span className="fa fa-info-circle" id="EuID" />
                </h3>
                <StyledTooltip trigger="hover" placement="top" target="EuID">
                  {data.trialDetailsEnJson.popoversText.euCtId}
                </StyledTooltip>
                <p>
                  {searchResult.ctis_id ? (
                    <span>{searchResult.ctis_id}</span>
                  ) : (
                    data.trialDetailsEnJson.notAvailable
                  )}
                </p>
              </div>
            </Col>
          </Row>
          <div id="cta-button-container">
            <PrescreenerButton nctId={searchResult.nct_number} />
          </div>
        </DetailsHero>
        <TrialPurposeSection>
          <Row>
            <Col
              xs={{ size: 12, order: 2 }}
              md={{ size: 4, order: 1 }}
              className={'align-center'}
            >
              {searchResult.HasResults &&
              searchResult.attachments !== null &&
              typeof searchResult.attachments !== 'undefined' ? (
                <Modal
                  open={this.state.modalOpen}
                  type="view-results"
                  showModal={this.showModal}
                  hideModal={this.hideModal}
                  buttonText={data.trialDetailsEnJson.viewResults}
                >
                  <DownloadPopup
                    attachments={searchResult.attachments}
                    hideModal={this.hideModal}
                    trackAnalytics={attachment => {
                      trackAnalytics('DocumentDownload', {
                        attachment,
                        TrialId: searchResult.org_study_id,
                        TrialTitle: searchResult.brief_title,
                        TrialStatus: searchResult.display_status_internal,
                        Conditions: searchResult.conditions
                          ? searchResult.conditions.map(
                              cond => cond.InternalValue
                            )
                          : [],
                      });
                    }}
                  />
                </Modal>
              ) : null}
              <Modal
                open={this.state.modalOpen}
                type="ask-to-join"
                showModal={this.showModal}
                hideModal={this.hideModal}
                buttonText={data.trialDetailsEnJson.askQuestion}
                arrowRight={true}
                trackAnalytics={() => {
                  trackAnalytics('TrialContactClick', {
                    TrialId: searchResult.org_study_id,
                    PositionInList: -1,
                    TrialTitle: searchResult.brief_title,
                    TrialStatus: searchResult.display_status_internal,
                    LocationPosition: -1,
                    Conditions: searchResult.conditions
                      ? searchResult.conditions.map(cond => cond.InternalValue)
                      : [],
                  });
                }}
              >
                <ContactModal>
                  <ContactForm
                    isModal={true}
                    itemId={searchResult.org_study_id}
                    title={
                      searchResult['brief_title_OneLinkTx']
                        ? searchResult['brief_title_OneLinkTx']
                        : searchResult['brief_title:OneLinkTx']
                        ? searchResult['brief_title:OneLinkTx']
                        : searchResult.brief_title
                        ? searchResult.brief_title
                        : ''
                    }
                    formType="additionalquestions"
                    hideModal={this.hideModal}
                  />
                </ContactModal>
              </Modal>
            </Col>
            <Col xs={{ size: 12, order: 1 }} md={{ size: 4, order: 2 }}>
              <div className={'overall-section'}>
                <div className="icon-container">
                  <div
                    className={
                      'status-icon ' +
                      `${searchResult.display_status_internal}`.toLocaleLowerCase()
                    }
                  />
                  <span className="fa fa-info-circle" id="overall-status" />
                </div>
                {searchResult.display_status}
                <StyledTooltip
                  trigger="hover"
                  placement="top"
                  target="overall-status"
                >
                  {searchResult.display_status_internal ===
                  'RECSTATUS_STUDY_COMPLETED'
                    ? data.trialDetailsEnJson.popoversText.completed
                    : searchResult.display_status_internal ===
                      'RECSTATUS_RECRUITING'
                    ? data.trialDetailsEnJson.popoversText.recruiting
                    : searchResult.display_status_internal ===
                      'RECSTATUS_NOT_YET_RECRUITING'
                    ? data.trialDetailsEnJson.popoversText.notRecruiting
                    : searchResult.display_status_internal ===
                      'RECSTATUS_COMPLETED'
                    ? data.trialDetailsEnJson.popoversText.recruitmentComplete
                    : searchResult.display_status_internal ===
                      'RECSTATUS_TERMINATED'
                    ? data.trialDetailsEnJson.popoversText.terminated
                    : ''}
                </StyledTooltip>
              </div>
            </Col>
            <Col xs={{ size: 12, order: 3 }} md={{ size: 4, order: 3 }}>
              {searchResult.nct_number ? (
                <PreloaderConnectInfo
                  studyIds={[searchResult.nct_number]}
                  hasSpinner={false}
                >
                  <PrescreenerButton nctId={searchResult.nct_number} />
                </PreloaderConnectInfo>
              ) : null}
            </Col>
          </Row>
          <h2 className="main-title">{data.trialDetailsEnJson.trialPurpose}</h2>
          <p className="brief-summary">
            {nl2br(
              searchResult['brief_summary_OneLinkTx']
                ? searchResult['brief_summary_OneLinkTx']
                : searchResult['brief_summary:OneLinkTx']
                ? searchResult['brief_summary:OneLinkTx']
                : searchResult.brief_summary
                ? searchResult.brief_summary
                : ''
            )}
          </p>
          <div className="banner-container">
            <div className="banner-left">
              <h3>{data.trialDetailsEnJson.keyRequirement}</h3>
              <div className="requirements-container">
                <div className="gender">
                  <h4>{data.trialDetailsEnJson.gender}</h4>
                  <div
                    className={
                      'gender-icon ' +
                      `${searchResult.gender}`.toLocaleLowerCase()
                    }
                  />
                </div>
                <div className="age">
                  <h4>{data.trialDetailsEnJson.age}</h4>
                  <div className="age-range">
                    <h3
                      className={
                        searchResult.minimum_age_raw &&
                        !searchResult.minimum_age_raw.includes('N/A')
                          ? 'min'
                          : ''
                      }
                    >
                      {searchResult.minimum_age_raw &&
                      !searchResult.minimum_age_raw.includes('N/A')
                        ? searchResult.minimum_age_raw
                        : searchResult.maximum_age_raw &&
                          !searchResult.maximum_age_raw.includes('N/A')
                        ? `0\n ${data.trialDetailsEnJson.years}`
                        : null}
                    </h3>
                    <h3>
                      {searchResult.maximum_age_raw &&
                      !searchResult.maximum_age_raw.includes('N/A')
                        ? searchResult.maximum_age_raw
                        : data.trialDetailsEnJson.noLimit}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="banner-right">
              <h3>{data.trialDetailsEnJson.inclusion}</h3>
              {searchResult.inclusion && searchResult.inclusion !== '-' ? (
                <div>
                  <ClampLines
                    text={`${searchResult.inclusion}`}
                    id="text"
                    lines={3}
                    innerElement="div"
                    ellipsis="..."
                    buttons={false}
                  />
                </div>
              ) : (
                <p>{data.trialDetailsEnJson.noInclusion}</p>
              )}
              <Modal
                open={this.state.modalOpen}
                type="criteria"
                showModal={this.showModal}
                hideModal={this.hideModal}
                buttonText={data.trialDetailsEnJson.more}
                trackAnalytics={() => {
                  trackAnalytics('InclusionExclusionClick', {
                    TrialId: searchResult.org_study_id,
                    PositionInList: -1,
                    TrialTitle: searchResult.brief_title,
                    TrialStatus: searchResult.display_status_internal,
                    Conditions: searchResult.conditions
                      ? searchResult.conditions.map(cond => cond.InternalValue)
                      : [],
                  });
                }}
              >
                <Criteria
                  titleInc={data.trialDetailsEnJson.inclusion}
                  titleExc={data.trialDetailsEnJson.exclusion}
                  textInc={searchResult.inclusion}
                  textExc={searchResult.exclusion}
                  noInclusion={data.trialDetailsEnJson.noInclusion}
                  noExclusion={data.trialDetailsEnJson.noExclusion}
                  hideModal={this.hideModal}
                />
              </Modal>
              <h3>{data.trialDetailsEnJson.exclusion}</h3>
              {searchResult.exclusion && searchResult.exclusion !== '-' ? (
                <div>
                  <ClampLines
                    text={`${searchResult.exclusion}`}
                    id="text"
                    lines={3}
                    innerElement="div"
                    ellipsis="..."
                    buttons={false}
                  />
                </div>
              ) : (
                <p>{data.trialDetailsEnJson.noExclusion}</p>
              )}
              <Modal
                open={this.state.modalOpen}
                type="criteria"
                showModal={this.showModal}
                hideModal={this.hideModal}
                buttonText={data.trialDetailsEnJson.more}
                trackAnalytics={() => {
                  trackAnalytics('InclusionExclusionClick', {
                    TrialId: searchResult.org_study_id,
                    PositionInList: -1,
                    TrialTitle: searchResult.brief_title,
                    TrialStatus: searchResult.display_status_internal,
                    Conditions: searchResult.conditions
                      ? searchResult.conditions.map(cond => cond.InternalValue)
                      : [],
                  });
                }}
              >
                <Criteria
                  titleInc={data.trialDetailsEnJson.inclusion}
                  titleExc={data.trialDetailsEnJson.exclusion}
                  textInc={searchResult.inclusion}
                  textExc={searchResult.exclusion}
                  noInclusion={data.trialDetailsEnJson.noInclusion}
                  noExclusion={data.trialDetailsEnJson.noExclusion}
                  hideModal={this.hideModal}
                />
              </Modal>
            </div>
          </div>
          <div className="criteria-print">
            <h5>{data.trialDetailsEnJson.inclusion}</h5>
            <pre>
              {searchResult.inclusion && searchResult.inclusion !== '-'
                ? searchResult.inclusion
                : data.trialDetailsEnJson.noInclusion}
            </pre>
            <h5>{data.trialDetailsEnJson.exclusion}</h5>
            <pre>
              {searchResult.exclusion && searchResult.exclusion !== '-'
                ? searchResult.exclusion
                : data.trialDetailsEnJson.noExclusion}
            </pre>
          </div>
        </TrialPurposeSection>
        <TrialSummarySection>
          <h2 className="main-title">{data.trialDetailsEnJson.trialSummary}</h2>
          {data.trialDetailsEnJson.summarySection.map((summaryItem, index) => (
            <Row className={'trial-summary-row'} key={index}>
              <Col xs={12} lg={4} className="summary-col">
                <Card className={'trial-summary-card'}>
                  <CardBody>
                    <h6>
                      {`${summaryItem.enrollment}\n`}
                      <span className="fa fa-info-circle" id="enrollment" />
                    </h6>
                    <div className="summary-details">
                      {searchResult.enrollment && searchResult.enrollment.length
                        ? searchResult.enrollment
                        : data.trialDetailsEnJson.shortNotAvailable}
                    </div>
                    <StyledTooltip
                      trigger="hover"
                      placement="top"
                      target="enrollment"
                    >
                      {data.trialDetailsEnJson.popoversText.enrollment}
                    </StyledTooltip>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={12} lg={4} className="summary-col">
                <Card className={'trial-summary-card'}>
                  <CardBody>
                    <h6>
                      {`${summaryItem.dates}\n`}
                      <span className="fa fa-info-circle" id="dates" />
                    </h6>
                    <Row className="summary-details dates">
                      <Col xs={5} className="trial-dates-align">
                        {searchResult.start_date ? (
                          <div>
                            <Moment format="MMMM">
                              {searchResult.start_date}
                            </Moment>
                            <Moment format="YYYY">
                              {searchResult.start_date}
                            </Moment>
                          </div>
                        ) : (
                          data.trialDetailsEnJson.shortNotAvailable
                        )}
                      </Col>
                      <Col xs={2} className="trial-dates-align">
                        <img src="/images/black-arrow.png" alt="black-arrow" />
                      </Col>
                      <Col xs={5} className="trial-dates-align">
                        {searchResult.last_follow_up_date ? (
                          <div>
                            <Moment format="MMMM">
                              {searchResult.last_follow_up_date}
                            </Moment>
                            <Moment format="YYYY">
                              {searchResult.last_follow_up_date}
                            </Moment>
                          </div>
                        ) : (
                          data.trialDetailsEnJson.shortNotAvailable
                        )}
                      </Col>
                    </Row>
                    <StyledTooltip
                      trigger="hover"
                      placement="top"
                      target="dates"
                    >
                      {data.trialDetailsEnJson.popoversText.trialDates}
                    </StyledTooltip>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={12} lg={4} className="summary-col">
                <Card className={'trial-summary-card'}>
                  <CardBody>
                    <h6>
                      {`${summaryItem.phase}\n`}
                      <span className="fa fa-info-circle" id="phase" />
                    </h6>
                    <div className="summary-details phase">
                      {searchResult.phase
                        ? searchResult.phase.replace(/Phase /g, '')
                        : data.trialDetailsEnJson.shortNotAvailable}
                      <span className="fa fa-info-circle" id="phase-detailed" />
                    </div>
                    <StyledTooltip
                      trigger="hover"
                      placement="top"
                      target="phase"
                    >
                      {data.trialDetailsEnJson.popoversText.phase}
                    </StyledTooltip>
                    <StyledTooltip
                      trigger="hover"
                      placement="top"
                      target="phase-detailed"
                    >
                      {searchResult.phase.replace(/Phase /g, '') === '1'
                        ? data.trialDetailsEnJson.popoversText.phaseDetails_1
                        : ['2', '3', '4'].includes(
                            searchResult.phase.replace(/Phase /g, '')
                          )
                        ? data.trialDetailsEnJson.popoversText.phaseDetails_2
                        : data.trialDetailsEnJson.popoversText.phaseNA}
                    </StyledTooltip>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={12} lg={4} className="summary-col">
                <Card className={'trial-summary-card'}>
                  <CardBody>
                    <h6>
                      {`${summaryItem.placebo}\n`}
                      <span className="fa fa-info-circle" id="placebo" />
                    </h6>
                    <div className="summary-details">
                      {searchResult.interventions &&
                      searchResult.interventions.length &&
                      searchResult.interventions.filter(int =>
                        int.intervention_name
                          .toLocaleLowerCase()
                          .includes('placebo')
                      ).length
                        ? data.trialDetailsEnJson.yes
                        : data.trialDetailsEnJson.no}
                    </div>
                    <StyledTooltip
                      trigger="hover"
                      placement="top"
                      target="placebo"
                    >
                      {data.trialDetailsEnJson.popoversText.placebo}
                    </StyledTooltip>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={12} lg={4} className="summary-col">
                <Card className={'trial-summary-card'}>
                  <CardBody>
                    <h6>
                      {`${summaryItem.product}\n`}
                      <span className="fa fa-info-circle" id="product" />
                    </h6>
                    <div
                      className={
                        searchResult['product_name_OneLinkTx'] === 'N/A' ||
                        searchResult['product_name:OneLinkTx'] === 'N/A' ||
                        searchResult.product_name === 'N/A' ||
                        (searchResult['product_name_OneLinkTx'] &&
                          searchResult['product_name_OneLinkTx'].length ===
                            0) ||
                        (searchResult['product_name:OneLinkTx'] &&
                          searchResult['product_name:OneLinkTx'].length ===
                            0) ||
                        searchResult.product_name.length === 0
                          ? 'summary-details product not_available'
                          : 'summary-details product'
                      }
                    >
                      {searchResult['product_name_OneLinkTx']
                        ? searchResult['product_name_OneLinkTx']
                        : searchResult['product_name:OneLinkTx']
                        ? searchResult['product_name:OneLinkTx']
                        : searchResult.product_name
                        ? searchResult.product_name
                        : data.trialDetailsEnJson.shortNotAvailable}
                    </div>
                    <StyledTooltip
                      trigger="hover"
                      placement="top"
                      target="product"
                    >
                      {data.trialDetailsEnJson.popoversText.product}
                    </StyledTooltip>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={12} lg={4} className="summary-col">
                <Card className={'trial-summary-card'}>
                  <CardBody>
                    <h6>
                      {`${summaryItem.acceptHealthyVol}\n`}
                      <span className="fa fa-info-circle" id="healthyVol" />
                    </h6>
                    <div className="summary-details healthyVol">
                      {searchResult.healthy_volunteers
                        ? searchResult.healthy_volunteers
                        : data.trialDetailsEnJson.shortNotAvailable}
                    </div>
                    <StyledTooltip
                      trigger="hover"
                      placement="top"
                      target="healthyVol"
                    >
                      {data.trialDetailsEnJson.popoversText.acceptHealthyVol}
                    </StyledTooltip>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ))}
        </TrialSummarySection>
        <div>
          <h2 className="main-title" id="where-to-participate">
            {data.trialDetailsEnJson.mapSectionTitle}
          </h2>
          <MapContainer>
            {checkUserCountry()
              .toLocaleLowerCase()
              .includes('cn') ? (
              ''
            ) : // Baidu map component
            // <BaiduMap
            //   data={data}
            //   searchResult={searchResult}
            //   searchLat={this.state.searchLat}
            //   searchLong={this.state.searchLong}
            //   markers={searchResult.locations.map(location => ({
            //     position: {
            //       lat: location.Latitude,
            //       lng: location.Longitude,
            //     },
            //     locationDetails: {
            //       Name: location.Name,
            //       conditions:
            //         searchResult.conditions && searchResult.conditions.length
            //           ? searchResult.conditions
            //               .map(cond =>
            //                 cond['DisplayValue:OneLinkTx']
            //                   ? cond['DisplayValue:OneLinkTx']
            //                   : cond.DisplayValue
            //               )
            //               .join(', ')
            //           : data.trialDetailsEnJson.mapSection.notAvailable,
            //       City: location.City,
            //       StateDisplay: location.StateDisplay,
            //       PostalCode: location.PostalCode,
            //       CountryDisplay: location.CountryDisplay,
            //     },
            //   }))}
            // />
            this.getLocations().length ? (
              <Map
                data={data.trialDetailsEnJson.mapSection}
                studyLocations={this.getSortedLocation(this.getLocations())}
                setActiveLoc={this.setActiveLoc}
                isDynamic={true}
                hasCustomSearch={this.state.searchLat && this.state.searchLong}
              />
            ) : null}
          </MapContainer>
          <div
            className={[
              'fixed-table-header',
              searchResult.locations &&
                searchResult.locations.length > 5 &&
                'align-status',
            ]
              .filter(Boolean)
              .join(' ')}
          >
            <div>{data.trialDetailsEnJson.locations}</div>
            <div>
              {`${
                !this.statusToHideLocationStatus.includes(
                  this.state.searchResult.display_status_internal
                )
                  ? data.trialDetailsEnJson.status
                  : ''
              }`}
            </div>
            <div>
              <div className="locations-qualify-button">
                <PrescreenerButton
                  nctId={searchResult.nct_number}
                  setPrequlifyStatus={status => {
                    this.setState({ hasPrequalify: status });
                  }}
                />

                <Modal
                  open={this.state.modalOpen}
                  type="ask-to-join"
                  showModal={this.showModal}
                  hideModal={this.hideModal}
                  buttonText={data.trialDetailsEnJson.askQuestion}
                  arrowRight={true}
                  trackAnalytics={() => {
                    trackAnalytics('TrialContactClick', {
                      TrialId: searchResult.org_study_id,
                      PositionInList: -1,
                      TrialTitle: searchResult.brief_title,
                      TrialStatus: searchResult.display_status_internal,
                      LocationPosition: -1,
                      Conditions: searchResult.conditions
                        ? searchResult.conditions.map(
                            cond => cond.InternalValue
                          )
                        : [],
                    });
                  }}
                >
                  <ContactModal>
                    <ContactForm
                      isModal={true}
                      itemId={searchResult.org_study_id}
                      title={
                        searchResult['brief_title_OneLinkTx']
                          ? searchResult['brief_title_OneLinkTx']
                          : searchResult['brief_title:OneLinkTx']
                          ? searchResult['brief_title:OneLinkTx']
                          : searchResult.brief_title
                          ? searchResult.brief_title
                          : ''
                      }
                      formType="additionalquestions"
                      hideModal={this.hideModal}
                    />
                  </ContactModal>
                </Modal>
              </div>
            </div>
          </div>
          <DetailsTable>{this.renderResultsTable()}</DetailsTable>
        </div>
        {data.trialDetailsEnJson.trialDesign.map((designItem, index) => (
          <TrialDesignSection key={index}>
            {searchResult.primary_outcomes &&
            searchResult.primary_outcomes.length > 0 &&
            searchResult.primary_outcomes['0'] &&
            searchResult.primary_outcomes['0'].outcome_measure !== null &&
            typeof searchResult.primary_outcomes['0'].outcome_measure !==
              'undefined' ? (
              <div>
                <h2 className="title">
                  {designItem.title}
                  {this.state.trialDesignCollapsed ? (
                    <FaChevronUp onClick={this.collapseTrialDesign} />
                  ) : (
                    <FaChevronDown onClick={this.collapseTrialDesign} />
                  )}
                </h2>
                <Collapse isOpen={this.state.trialDesignCollapsed}>
                  <p className="subtitle">{searchResult.official_title}</p>
                  <Row>
                    <Col lg={6}>
                      <div className="green-container">
                        <div className="align-text">
                          <p>
                            {designItem.trialType}
                            <span
                              className="fa fa-info-circle"
                              id="studyType"
                            />
                            :
                          </p>
                          <StyledTooltip
                            trigger="hover"
                            placement="top"
                            target="studyType"
                          >
                            {data.trialDetailsEnJson.popoversText.studyType}
                          </StyledTooltip>
                          {searchResult.study_type &&
                          searchResult.study_type.length &&
                          searchResult.study_type !== 'N/A' ? (
                            <p>
                              {searchResult.study_type}
                              <span
                                className="fa fa-info-circle"
                                id="studyType-details"
                              />
                            </p>
                          ) : (
                            <p>{data.trialDetailsEnJson.shortNotAvailable}</p>
                          )}
                          {searchResult.study_type &&
                            searchResult.study_type.length &&
                            searchResult.study_type !== 'N/A' && (
                              <StyledTooltip
                                trigger="hover"
                                placement="top"
                                target="studyType-details"
                              >
                                {searchResult.study_type === 'Interventional'
                                  ? data.trialDetailsEnJson.popoversText
                                      .studyTypeInterventional
                                  : searchResult.study_type === 'Observational'
                                  ? data.trialDetailsEnJson.popoversText
                                      .studyTypeObservational
                                  : null}
                              </StyledTooltip>
                            )}
                        </div>
                        <div className="align-text">
                          <p>
                            {designItem.interventionType}
                            <span
                              className="fa fa-info-circle"
                              id="interventionType"
                            />
                            :
                          </p>
                          <StyledTooltip
                            trigger="hover"
                            placement="top"
                            target="interventionType"
                          >
                            {
                              data.trialDetailsEnJson.popoversText
                                .interventionType
                            }
                          </StyledTooltip>
                          <p>
                            {searchResult.interventions &&
                            searchResult.interventions.length
                              ? searchResult.interventions[0].intervention_type
                              : data.trialDetailsEnJson.shortNotAvailable}
                          </p>
                        </div>
                        <div className="align-text">
                          <p>
                            {designItem.trialPurpose}
                            <span
                              className="fa fa-info-circle"
                              id="trialPurpose"
                            />
                            :
                          </p>
                          <StyledTooltip
                            trigger="hover"
                            placement="top"
                            target="trialPurpose"
                          >
                            {data.trialDetailsEnJson.popoversText.trialPurpose}
                          </StyledTooltip>
                          <p>
                            {searchResult.interventional_subtype &&
                            searchResult.interventional_subtype.length &&
                            searchResult.interventional_subtype !== 'N/A'
                              ? searchResult.interventional_subtype
                              : data.trialDetailsEnJson.shortNotAvailable}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="green-container right">
                        <div className="align-text">
                          <p>
                            {designItem.allocation}
                            <span
                              className="fa fa-info-circle"
                              id="allocation"
                            />
                            :
                          </p>
                          <StyledTooltip
                            trigger="hover"
                            placement="top"
                            target="allocation"
                          >
                            {data.trialDetailsEnJson.popoversText.allocation}
                          </StyledTooltip>
                          {searchResult.allocation &&
                          searchResult.allocation.length &&
                          searchResult.allocation !== 'N/A' ? (
                            <div>
                              <p>
                                {searchResult.allocation}
                                <span
                                  className="fa fa-info-circle"
                                  id="allocation-details"
                                />
                              </p>
                              <StyledTooltip
                                trigger="hover"
                                placement="top"
                                target="allocation-details"
                              >
                                {searchResult.allocation === 'Randomized'
                                  ? data.trialDetailsEnJson.popoversText
                                      .allocationRandomized
                                  : searchResult.allocation === 'Non-randomized'
                                  ? data.trialDetailsEnJson.popoversText
                                      .allocationNonRandomized
                                  : null}
                              </StyledTooltip>
                            </div>
                          ) : (
                            <p>{data.trialDetailsEnJson.shortNotAvailable}</p>
                          )}
                        </div>
                        <div className="align-text">
                          <p>
                            {designItem.blinding}
                            <span className="fa fa-info-circle" id="blinding" />
                            :
                          </p>
                          <StyledTooltip
                            trigger="hover"
                            placement="top"
                            target="blinding"
                          >
                            {data.trialDetailsEnJson.popoversText.blinding}
                          </StyledTooltip>
                          {searchResult.masking &&
                          searchResult.masking.length &&
                          searchResult.masking !== 'N/A' ? (
                            <div>
                              <p>
                                {searchResult.masking}
                                <span
                                  className="fa fa-info-circle"
                                  id="blinding-details"
                                />
                              </p>
                              <StyledTooltip
                                trigger="hover"
                                placement="top"
                                target="blinding-details"
                              >
                                {searchResult.masking === 'Open Label'
                                  ? data.trialDetailsEnJson.popoversText
                                      .blindingOpenLabel
                                  : searchResult.masking === 'Double Blind'
                                  ? data.trialDetailsEnJson.popoversText
                                      .blindingDoubleBlind
                                  : null}
                              </StyledTooltip>
                            </div>
                          ) : (
                            <p>{data.trialDetailsEnJson.shortNotAvailable}</p>
                          )}
                        </div>
                        <div className="align-text">
                          <p>
                            {designItem.assignment}
                            <span
                              className="fa fa-info-circle"
                              id="assignment"
                            />
                            :
                          </p>
                          <StyledTooltip
                            trigger="hover"
                            placement="top"
                            target="assignment"
                          >
                            {data.trialDetailsEnJson.popoversText.assignment}
                          </StyledTooltip>
                          {searchResult.assignment &&
                          searchResult.assignment.length &&
                          searchResult.assignment !== 'N/A' ? (
                            <div>
                              <p>
                                {searchResult.assignment}
                                {[
                                  'Single Group',
                                  'Single Group Assignment',
                                  'Parallel Assignment',
                                ].includes(searchResult.assignment) ? (
                                  <span
                                    className="fa fa-info-circle"
                                    id="assignment-details"
                                  />
                                ) : null}
                              </p>
                              {[
                                'Single Group',
                                'Single Group Assignment',
                                'Parallel Assignment',
                              ].includes(searchResult.assignment) ? (
                                <StyledTooltip
                                  trigger="hover"
                                  placement="top"
                                  target="assignment-details"
                                >
                                  {searchResult.assignment === 'Single Group' ||
                                  searchResult.assignment ===
                                    'Single Group Assignment'
                                    ? data.trialDetailsEnJson.popoversText
                                        .assignmentSingleGroup
                                    : searchResult.assignment ===
                                      'Parallel Assignment'
                                    ? data.trialDetailsEnJson.popoversText
                                        .assignmentParallelAssignment
                                    : null}
                                </StyledTooltip>
                              ) : null}
                            </div>
                          ) : (
                            <p>{data.trialDetailsEnJson.shortNotAvailable}</p>
                          )}
                        </div>
                        <div className="align-text">
                          <p>
                            {designItem.trialArms}
                            <span
                              className="fa fa-info-circle"
                              id="trialArms"
                            />
                            :
                          </p>
                          <StyledTooltip
                            trigger="hover"
                            placement="bottom"
                            target="trialArms"
                            modifiers={{ flip: { behavior: ['bottom'] } }}
                            className={'xl'}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  data.trialDetailsEnJson.popoversText.trialArms
                                    .childMarkdownRemark.html,
                              }}
                            />
                          </StyledTooltip>
                          {searchResult.arm_groups &&
                          searchResult.arm_groups.length &&
                          searchResult.arm_groups !== 'N/A' ? (
                            <p>{searchResult.arm_groups.length}</p>
                          ) : (
                            <p>{data.trialDetailsEnJson.shortNotAvailable}</p>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {searchResult.primary_outcomes &&
                  searchResult.primary_outcomes.length > 0 &&
                  searchResult.primary_outcomes['0'] &&
                  searchResult.primary_outcomes['0'].outcome_measure !==
                    null ? (
                    <div>
                      <h2 className="main-title">
                        {designItem.primaryOutcome}
                        <span
                          className="fa fa-info-circle"
                          id="primaryOutcome"
                        />
                      </h2>
                      <StyledTooltip
                        trigger="hover"
                        placement="top"
                        target="primaryOutcome"
                      >
                        {data.trialDetailsEnJson.popoversText.primaryOutcome}
                      </StyledTooltip>
                      <div className="green-container outcome">
                        {searchResult.primary_outcomes.map((outcome, index) => (
                          <ul key={index}>
                            <li>
                              <div className="measure">
                                <strong>{outcome.outcome_measure}</strong>
                              </div>
                              {outcome.outcome_description ? (
                                <div>{outcome.outcome_description}</div>
                              ) : null}
                              <div>
                                <strong className="timeframe">
                                  {designItem.outcomeTimeFrame}
                                </strong>
                                {`: ${outcome.outcome_time_frame}`}
                              </div>
                            </li>
                          </ul>
                        ))}
                      </div>
                    </div>
                  ) : null}
                  {searchResult.secondary_outcomes &&
                  searchResult.secondary_outcomes.length > 0 &&
                  searchResult.secondary_outcomes['0'] &&
                  searchResult.secondary_outcomes['0'].outcome_measure !==
                    null ? (
                    <div>
                      <h2 className="main-title">
                        {designItem.secondaryOutcome}
                        <span
                          className="fa fa-info-circle"
                          id="secondaryOutcome"
                        />
                      </h2>
                      <StyledTooltip
                        trigger="hover"
                        placement="top"
                        target="secondaryOutcome"
                      >
                        {data.trialDetailsEnJson.popoversText.secondaryOutcome}
                      </StyledTooltip>
                      <div className="green-container outcome">
                        {searchResult.secondary_outcomes.map(
                          (outcome, index) => (
                            <ul key={index}>
                              <li>
                                <div className="measure">
                                  <strong>{outcome.outcome_measure}</strong>
                                </div>
                                {outcome.outcome_description ? (
                                  <div>{outcome.outcome_description}</div>
                                ) : null}
                                <div>
                                  <strong className="timeframe">
                                    {designItem.outcomeTimeFrame}
                                  </strong>
                                  {`: ${outcome.outcome_time_frame}`}
                                </div>
                              </li>
                            </ul>
                          )
                        )}
                      </div>
                    </div>
                  ) : null}
                  <h2 className="main-title">
                    {designItem.additionalInfoTitle}
                  </h2>
                  <div className="additional-info">
                    {searchResult.links && searchResult.links.length ? (
                      searchResult.links.map((link, index) => (
                        <Modal
                          key={index}
                          open={this.state.modalOpen}
                          type="redirect"
                          showModal={() => this.showModal()}
                          hideModal={() => this.hideModal()}
                          buttonText={link.description}
                          arrowRight={true}
                        >
                          <Redirect hideModal={this.hideModal} url={link.url} />
                        </Modal>
                      ))
                    ) : (
                      <p>{data.trialDetailsEnJson.notAvailable}</p>
                    )}
                  </div>
                </Collapse>
              </div>
            ) : (
              <div>
                <h2 className="main-title small">
                  {data.trialDetailsEnJson.referToResults}
                </h2>
                <h2 className="main-title">{designItem.additionalInfoTitle}</h2>
                <div className="additional-info">
                  {searchResult.links && searchResult.links.length ? (
                    searchResult.links.map((link, index) => (
                      <Modal
                        key={index}
                        open={this.state.modalOpen}
                        type="redirect"
                        showModal={this.showModal}
                        hideModal={this.hideModal}
                        buttonText={link.description}
                        arrowRight={true}
                      >
                        <Redirect hideModal={this.hideModal} url={link.url} />
                      </Modal>
                    ))
                  ) : (
                    <p>{data.trialDetailsEnJson.notAvailable}</p>
                  )}
                </div>
              </div>
            )}
          </TrialDesignSection>
        ))}
      </ConnectDataContext.Provider>
    );
  };
  renderStudyNotFound = () => {
    let { data } = this.props;
    return (
      <Fonts>
        <StudyNotFoundContainer>
          <h1 className={'main-title'}>{data.studyNotFoundJson.title}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: data.studyNotFoundJson.content.childMarkdownRemark.html,
            }}
          />
        </StudyNotFoundContainer>
      </Fonts>
    );
  };

  render() {
    const content = this.renderContent();
    const studyNotFound = this.renderStudyNotFound();
    let { apiBadResponse } = this.state;
    const pageTitle =
      this.state.searchResult.conditions &&
      this.state.searchResult.conditions.length > 0
        ? this.state.searchResult.conditions
            .map(cond =>
              cond['LookupValue_OneLinkTx']
                ? cond['LookupValue_OneLinkTx']
                : cond['LookupValue:OneLinkTx']
                ? cond['LookupValue:OneLinkTx']
                : cond.LookupValue
                ? cond.LookupValue
                : cond.DisplayValue
            )
            .join(', ')
        : this.state.searchResult.brief_summary;

    return (
      <Layout
        meta={
          this.state.searchResult
            ? {
                pageTitle,
                pageDescription: `${this.state.searchResult.brief_title.substring(
                  0,
                  157
                )}...`,
                ...(typeof window !== 'undefined' && {
                  canonical: window.location.href,
                }),
                imageUrl: `${this.props.data.site.siteMetadata.siteUrl}/images/clinical_trials_explorer_logo.png`,
              }
            : {
                pageTitle:
                  'https://Clinicaltrials.bayer.com - Bayer - Clinical Trials Explorer',
                imageUrl: `${this.props.data.site.siteMetadata.siteUrl}/images/clinical_trials_explorer_logo.png`,
              }
        }
      >
        <Fonts>
          <DetailsPage>
            <Container>
              {apiBadResponse === true && this.props.data.studyDetails === null
                ? studyNotFound
                : content}
            </Container>
          </DetailsPage>
        </Fonts>
      </Layout>
    );
  }
}

TrialDetails.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
  pageContext: PropTypes.object,
  navigate: PropTypes.func,
};

export default TrialDetails;

export const query = graphql`
  query StudyDetailsQuery($studyId: String!) {
    studyNotFoundJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
    trialDetailsEnJson {
      ...TrialDetailsEnJsonFragment
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    studyDetails(org_study_id: { eq: $studyId }) {
      ...studyDetailsFragment
    }
  }
`;
