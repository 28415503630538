import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Map, InfoWindow, GoogleApiWrapper } from 'google-maps-react';
import { gMapApiKey, gApiLangCode } from '../../constants/config';
import { MarkerClusterer } from './cluster';
import { onBoundsChanged, checkUserCountry } from './map-utils';

const MapContainer = ({
  google,
  data,
  studyLocations,
  setActiveLoc,
  hasCustomSearch = false,
  isDynamic = false,
}) => {
  const [activeMarker, setActiveMarker] = useState(null);
  const [isInfoWindowVisible, showInfoWindow] = useState(false);
  const mapRef = useRef();
  const clusterRef = useRef();
  const markers = useRef([]);
  const uniquePins = useRef([]);
  const markerDescription = useRef([]);
  const activeMarkerRef = useRef();

  useEffect(
    () => {
      if (
        typeof window !== 'undefined' &&
        window.google &&
        window.google.maps
      ) {
        let bounds = new window.google.maps.LatLngBounds();
        if (studyLocations) {
          studyLocations.forEach(location => {
            if (location && location.Latitude && location.Longitude) {
              const key =
                '' +
                parseInt(location.Latitude * 10000) +
                '_' +
                parseInt(location.Longitude * 10000);
              if (typeof markerDescription.current[key] === 'undefined') {
                markerDescription.current[key] = [];
              }
              markerDescription.current[key].push(location);

              const loc = new window.google.maps.LatLng(
                location.Latitude,
                location.Longitude
              );
              bounds.extend(loc);

              const marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(
                  location.Latitude,
                  location.Longitude
                ),
                title: isDynamic
                  ? location.Name
                  : `${location.StudyId} - Study Location Details`,
                markersClusterKey: key,
                draggable: false,
                lat: location.Latitude,
                long: location.Longitude,
                icon: {
                  url: '/images/locationMarker.png',
                  scaledSize: new google.maps.Size(60, 60),
                  anchor: new google.maps.Point(30, 50),
                  origin: new google.maps.Point(0, 0),
                },
              });
              marker.setMap(mapRef.current.map);
              marker.addListener('click', () => {
                onMarkerClick(marker);
              });
              if (!uniquePins.current.includes(key)) {
                uniquePins.current.push(key);
                markers.current.push(marker);
              }
            }
          });
        }

        if (clusterRef.current === undefined) {
          mapRef.current.map.fitBounds(bounds);
          let zoom = mapRef.current.map.getZoom();
          mapRef.current.map.minZoom = 1;
          mapRef.current.map.setZoom(zoom > 6 ? 6 : zoom);
          clusterRef.current = new MarkerClusterer(
            mapRef.current.map,
            markers.current,
            {
              gridSize: 50,
              minimumClusterSize: 2,
              averageCenter: true,
              zoomOnClick: true,
              imagePath:
                'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
            }
          );
        }
        if (isDynamic) {
          if (
            mapRef &&
            mapRef.current &&
            mapRef.current.map !== null &&
            typeof mapRef.current.map !== 'undefined'
          ) {
            mapRef.current.map.addListener('bounds_changed', () => {
              setActiveLoc(
                onBoundsChanged(
                  studyLocations,
                  markers.current,
                  mapRef.current.map
                )
              );
            });
          }
        }

        setTimeout(() => {
          if (hasCustomSearch) {
            mapRef.current.map.setZoom(10);

            mapRef.current.map.setCenter({
              lat: parseFloat(studyLocations[0].Latitude),
              lng: parseFloat(studyLocations[0].Longitude),
            });
          }
        }, 100);

        return () => {
          markers.current.forEach(marker => {
            clusterRef.current.removeMarker(marker);
          });
          markerDescription.current = [];
          markers.current = [];
          uniquePins.current = [];
          clusterRef.current = undefined;
        };
      }
    },
    isDynamic ? [] : [studyLocations]
  );

  const onMarkerClick = marker => {
    mapRef.current.map.setCenter(marker.getPosition());
    setActiveMarker(marker);
    showInfoWindow(true);
  };

  const onInfoWindowClose = () => {
    activeMarkerRef.current = null;
    showInfoWindow(false);
  };

  const onMapClicked = () => {
    if (isInfoWindowVisible) {
      activeMarkerRef.current = null;
      showInfoWindow(false);
    }
  };
  return (
    <Map
      ref={mapRef}
      google={google}
      containerStyle={{
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
      initialCenter={{
        lat:
          parseFloat(
            studyLocations.length ? studyLocations[0].Latitude : 50.43
          ) || 50.43,
        lng:
          parseFloat(
            studyLocations.length ? studyLocations[0].Longitude : 26.08
          ) || 26.081807,
      }}
      zoom={3}
      mapTypeControl={false}
      onClick={onMapClicked}
    >
      <InfoWindow
        maxWidth={350}
        height={180}
        marker={activeMarker}
        onClose={onInfoWindowClose}
        visible={isInfoWindowVisible}
      >
        {activeMarker &&
        activeMarker.markersClusterKey &&
        markerDescription.current[activeMarker.markersClusterKey] ? (
          markerDescription.current[activeMarker.markersClusterKey].map(
            (locationDetails, idx) => (
              <div className="marker-text" key={idx}>
                <strong>
                  {locationDetails.Name !== null &&
                  locationDetails.Name.length > 0
                    ? locationDetails.Name
                    : data.title}
                </strong>
                <br />
                <strong>{data.conditions}</strong>: {locationDetails.conditions}
                <br />
                <strong>{data.studyId}</strong>: {locationDetails.StudyId}
                <br />
                <strong>{data.city}</strong>:{' '}
                {locationDetails.City != null
                  ? locationDetails.City
                  : data.notAvailable}
                <br />
                <strong>{data.state}</strong>:{' '}
                {locationDetails.StateDisplay != null
                  ? locationDetails.StateDisplay
                  : data.notAvailable}
                <br />
                <strong>{data.postalCode}</strong>:{' '}
                {locationDetails.PostalCode != null
                  ? locationDetails.PostalCode
                  : data.notAvailable}
                <br />
                <strong>{data.country}</strong>:{' '}
                {locationDetails.CountryDisplay != null
                  ? locationDetails.CountryDisplay
                  : data.notAvailable}
                {locationDetails.Contact && locationDetails.Contact.phone ? (
                  <>
                    <br />
                    <strong>{data.phone}</strong>:{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`tel:${locationDetails.Contact.phone}`}
                    >
                      {locationDetails.Contact.phone}
                    </a>
                  </>
                ) : null}
                {locationDetails.Contact && locationDetails.Contact.email ? (
                  <>
                    <br />
                    <strong>{data.email}</strong>:{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`tel:${locationDetails.Contact.email}`}
                    >
                      {locationDetails.Contact.email}
                    </a>
                  </>
                ) : null}
              </div>
            )
          )
        ) : (
          <div />
        )}
      </InfoWindow>
    </Map>
  );
};

MapContainer.propTypes = {
  data: PropTypes.object,
  studyLocations: PropTypes.array,
  conditons: PropTypes.string,
  google: PropTypes.object,
  setActiveLoc: PropTypes.func,
  isDynamic: PropTypes.bool,
  hasCustomSearch: PropTypes.bool,
};

export default GoogleApiWrapper({
  apiKey: gMapApiKey(),
  language: gApiLangCode(),
  region: checkUserCountry() === 'CN' ? 'CN' : '',
})(MapContainer);
