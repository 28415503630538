import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useConnectInfo from '../../helpers/useConnectInfo';
import { IoIosArrowForward } from 'react-icons/io';
import { PrescreenerButtonWrapper } from './PrescreenerButton.css';
import { getCurrentLangId } from '../../constants/config';

const PrescreenerButton = ({ nctId, setPrequlifyStatus }) => {
  const { getInfo } = useConnectInfo([nctId]);
  const studyConnectInfo = getInfo(nctId);
  const currentLangId = getCurrentLangId();
  const ctaButtonText =
    currentLangId === 'es' ? 'Ver si califico' : 'See if I Qualify';
  const displayStudy = shouldDisplayStudy(nctId, currentLangId);

  useEffect(() => {
    if (setPrequlifyStatus) {
      setPrequlifyStatus(
        studyConnectInfo && studyConnectInfo.status === 'active' && displayStudy
          ? true
          : false
      );
    }
  }, [displayStudy]);

  return studyConnectInfo &&
    studyConnectInfo.status === 'active' &&
    displayStudy ? (
    <PrescreenerButtonWrapper>
      <a
        href={`${studyConnectInfo.summary_url}/prescreener`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {ctaButtonText}
        <span>
          <IoIosArrowForward />
        </span>
      </a>
    </PrescreenerButtonWrapper>
  ) : null;
};

const shouldDisplayStudy = (nctId, currentLangId) => {
  const studiesRestrictedByLang = [
    {
      nctId: 'NCT05901831',
      restrictedLanguages: ['en', 'es'],
    },
  ];
  const foundStudy = studiesRestrictedByLang.find(s => s.nctId === nctId);
  if (!foundStudy) {
    return true;
  }
  return foundStudy.restrictedLanguages.includes(currentLangId.toLowerCase());
};

PrescreenerButton.propTypes = {
  nctId: PropTypes.string.isRequired,
  setPrequlifyStatus: PropTypes.func,
};

export default PrescreenerButton;
